export default {
  colors: {
    text: "#333",
    background: "#ffffff",
    primary: "#006699",
    secondary: "#040111",
    light: "#ffffff",
    dark: "#040111",
  },
  layout: {
    container: {
      maxWidth: ["455px", "580px", "740px", "960px", "1200px"],
      padding: ["1rem", "2rem"],
    },
  },
  fonts: {
    body: `'Work Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
    heading: `'Work Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 58, 72],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.3,
    heading: 1.125,
    normal: 1.5,
  },
  radii: {
    none: "0",
    sm: "0.125rem",
    default: "0.5rem",
    lg: "1rem",
    full: "9999px",
  },
  text: {
    default: {
      color: "text",
      fontSize: 2,
      fontFamily: "body",
      lineHeight: "body",
    },
    blog: {
      fontSize: 3,
      fontFamily: "body",
      lineHeight: "normal",
    },
    caption: {
      fontSize: 1,
      fontFamily: "body",
    },
    heading: {
      fontSize: 5,
      fontFamily: "heading",
      fontWeight: "body",
      lineHeight: "body",
    },
    display: {
      fontSize: 7,
      lineHeight: "heading",
    },
    displaySmall: {
      fontSize: 6,
    },
    headingSmall: {
      fontSize: 4,
    },
    lead: {
      fontSize: 4,
    },
    leadSmall: {
      fontSize: 3,
    },
    h1: {
      fontSize: [5, 6, 7],
      fontWeight: 800,
      lineHeight: "heading",
      marginBottom: 4,
    },
    h2: {
      fontSize: [5, 5, 6],
      fontWeight: 700,
      lineHeight: "heading",
      marginBottom: 3,
    },
    h3: {
      fontSize: 4,
      fontWeight: 700,
      lineHeight: "heading",
      marginBottom: 3,
    },
    h4: {
      fontSize: 3,
      fontWeight: 700,
      lineHeight: "heading",
    },
    h5: {
      fontSize: 3,
      fontWeight: 700,
      lineHeight: "heading",
    },
    h6: {
      fontSize: 3,
      fontWeight: 700,
      lineHeight: "heading",
    },
  },
  breakpoints: ["475px", "640px", "769px", "1024px", "1280px"],
  space: [0, 4, 8, 16, 24, 48, 64, 96],
  styles: {
    root: {
      "--swiper-theme-color": "#006699",
      ".swiper": {
        paddingBottom: 4,
      },
    },
  },
}
